.bookings-container {
    margin: 20px;
    font-family: Arial, sans-serif;
    flex: 1;
  }
  
  .bookings-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .bookings-table th,
  .bookings-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .bookings-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .bookings-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .bookings-table tr:hover {
    background-color: #ddd;
  }
  